import React from 'react'
import './mobilehealth.scss'
import iphone from '../../Assets/images/iphone.png'
// import part from "../../Assets/svg/iphone-part.svg";
import cross from '../../Assets/svg/cross-logo.svg'
// import Cross from '../../Assets/svg/cross';
import Button from '../Modules/Button'
function MobileHealth() {
  return (
    <div className="mobile">
    	<div className="container">
				<div className="mobile_wrap">
					<div className="mobile-left">
						<div className="main_title mob_only">
							<h2>Give your health a RevAiv!</h2>
						</div>
						<img src={iphone} alt="iphone" className="iphone" />
					</div>
					<div className="mobile-right">
						<div className="mobile-txt">
							<div className="main_title des_only">
								<h2>Give your health a RevAiv!</h2>
							</div>
							<p>We're here to support your health and reverse your symptoms.</p>
							<Button value={"Download App"} type={"contained"} />
						</div>
					</div>
				</div>
      </div>
			<div className="bg-icon">
				<img src={cross} alt='cross'/>
			</div>
    </div>
  );
}

export default MobileHealth
