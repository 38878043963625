import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link } from 'react-router-dom';

export default function DropBtn(props) {
  //const [list, setList] = useState([]);
  const [submenu, setSubmenu] = useState(props.submenu);

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
//   useEffect(() => {
//     let l1 = [];
//     if (props.value === "Services") {
//       l1 = ["RevAiv for Patients", "RevAiv for Doctors"];
//     } else {
//       l1 = ["Blogs", "FAQs"];
//     }
//     setList([...l1]);
//   }, []);
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      {/* <InputLabel id="demo-select-small-label">Age</InputLabel> */}
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={""}
        valueurl={""}
        label="Age"
        onChange={() => {}}
      >
        {submenu.map((item, index) => {
          return(
          <MenuItem value={item.key} valueUrl={item.url} key={index}>
            {/* {item.key} */}
			<Link to={item.url}>
				{item.key}
			</Link>
          </MenuItem>)
        })}
        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem> */}
      </Select>
    </FormControl>
  );
}
