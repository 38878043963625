import React from 'react'
import logo from '../Assets/svg/reviavlogo.svg'
import Button from './Modules/Button';
import DropBtn from './Modules/DropBtn';
import down from '../Assets/svg/down arrow.svg'
import menuOpen from '../Assets/svg/hamburger.svg'
import menuClose from '../Assets/svg/menuclose.svg'
import { useState } from 'react';
import { Link } from 'react-router-dom';
// const list = ["About us", "Services", "Symptoms", "Learn"];

let doctor = [<>RevAiv for Doctors <i>(coming soon)</i></>]

const listing = [
	{
		'key':'About us',
		'url':'about-us'
	},
	{
		'key':'Services',
		'url':'',
		'dropdown':true,
		'submenu': [
			{
				'key':'RevAiv for Patients',
				'url':'revaiv-for-patients'
			},
			{
				'key':doctor
			}
		]
	},
	{
		'key':'Symptoms',
		'url':'symptoms'
	},
	{
		'key':'Learn',
		'url':'',
		'dropdown':true,
		'submenu': [
			{
				'key':'Blogs',
				'url':'https://blog.revaiv.app'
			},
			{
				'key':'FAQs',
				'url':'faqs'
			}
		]
	}	
]

function Header() {
	const [menuClassToggle, menuToggle] = useState('');
	const menuClick = event => {
		menuToggle('open');
		if(menuClassToggle =='open') {
			menuToggle('');
		}
	};
  return (
    <div className="header">
		<div className="container">
			<div className="header_row">
				<div className="logo">
					<Link to="/">
						<img src={logo} />
					</Link>
				</div>
				<div className="header_hamburger_wrap">
					<div className={`header_hamburger ${menuClassToggle}`} onClick={menuClick}>
						<img src={menuOpen} alt="Menu" />
						<img src={menuClose} alt="Menu" />
					</div>
				</div>
				<div className={`header_nav ${menuClassToggle}`}>
					<div className="options">
						{listing.map((item, index) => {
						return (
							<div key={index} className="option normal-text">
							{/* {(index + 1) % 2 === 0 ? (
								<>
								<div>
									{item.key}
									<img src={down} alt="down arrow" />
								</div>
								<DropBtn value={item} />
								</>
							) : (
								item.key
							)} */}
							{
								(item.dropdown == true) ? <>
									<div>
										{item.key}
										<img src={down} alt="down arrow" />
									</div>
									<DropBtn submenu={item.submenu}/>
								</> : ( <>
									<Link to={item.url}>
										{item.key}
									</Link>
								</>
								)
							}
							</div>
						);
						})}
					</div>
					<Button value={"Get Started"} type={'contained'} />
				</div>
			</div>
		</div>
	</div>
  );
}

export default Header
