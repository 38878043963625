import React, { useEffect, useState } from "react";
import RevLogo from "../../Assets/svg/revlogo2.svg";
import androidlogo from "../../Assets/svg/android white.svg";
import applelogo from "../../Assets/svg/apple white.svg";
import fb from "../../Assets/svg/fbicon.svg";
import linkedin from "../../Assets/svg/linkedinicon.svg";
import insta from "../../Assets/svg/instaicon.svg";
import twitter from "../../Assets/svg/twittericon.svg";
// import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function BottomListItems(props) {
  const [url, setUrl] = useState('');
  // const navigate = useNavigate();
  const [list, setList] = useState([]);
  const itemSelect = (item) => {
    if (item === "Terms & Conditions") {
      window.open(`${url}/#/terms-conditions`, "_self");
    } else if (item === "Privacy Policy") {
      window.open(`${url}/#/privacy-policy`, "_self");
    } else if (item === "Contact") {
      window.open(`${url}/#/contact-us`, "_self");
    } else if (item === "Contact Us") {
      window.open(`${url}/#/contact-us`, "_self");
    } else if (item === "FAQs") {
      window.open(`${url}/#/faqs`, "_self");
    } else if (item === "Delete Account") {
      window.open(`${url}/#/delete-account`, "_self");
    } else if (item === "About Us") {
      window.open(`${url}/#/about-us`, "_self");
    }
  }

  const getSocial = (item) => {
    //   console.log('item',item)
    window.open(item.link, "_self");
  }

  useEffect(() => {
    let newList = [];
    if (props.type === "about") {
      newList = [
        "About Us",
        "Contact Us",
      ];
    } else if (props.type === "support") {
      newList = [
        "FAQs",
        "Contact Us",
        "Delete Account"
      ];
    } else if (props.type === "more") {
      newList = [
        "Terms & Conditions",
        "Privacy Policy",
      ];
    } else if (props.type === "icons") {
      newList = [
        { icon: RevLogo, link: "/" },
        { icon: androidlogo, link: "https://play.google.com/store/apps/details?id=app.revaiv" },
        { icon: applelogo, link: "https://apps.apple.com/app/revaiv/id6477476967" },
      ];
    } else {
      newList = [
        { icon: fb, link: "https://www.facebook.com/revaivapp" },
        { icon: insta, link: "https://www.instagram.com/revaiv.app" },
        { icon: linkedin, link: "https://linkedin.com/company/revaiv" },
        { icon: twitter, link: "https://twitter.com/revaivapp" },
      ];

    }
    setList([...newList]);
    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);

    // Get the base URL
    // const baseUrl = urlObject.origin;
    // setUrl(baseUrl)
    // console.log(currentUrl);
  }, []);

  return (
    <div
      className={`list-one list ${props.type === "icons"
        ? "icons-list"
        : props.type === "social"
          ? "social-icon-list"
          : "non-icons-list"
        }`}
    >
      {(props.type === "about" ||
        props.type === "support" ||
        props.type === "more" ||
        props.type === "social") && (
          <div className="list-item list-title">
            {props.type === "about"
              ? "About us"
              : props.type === "support"
                ? "Support"
                : props.type === "more"
                  ? "More"
                  : "Get Social"}
            <div className="title-underline"></div>
          </div>
        )}
      <div
        className={`list-contents ${props.type === "social" && "social-list"
          }`}
      >
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={`list-item ${props.type === "icons"
                ? "icon-list-item"
                : props.type === "social"
                  ? "social-icon-list-item"
                  : "text-list"
                }`}
            >
              {props.type === "icons" || props.type === "social" ? (
                <img src={item.icon ?? item} alt="rev logo" onClick={() => getSocial(item)} />
              ) : (
                <div onClick={() => itemSelect(item)}>
                  {/* {(item === "Terms & Conditions" ||
                  item === "Privacy Policy" )? (
                    <Router>
                      <nav>
                        <Link to={item === "Terms & Conditions" ? "/terms-conditions" : "/privacy-policy"}>{item}</Link>
                      </nav>
                    </Router>
                  ) : ( */}
                  {item}
                  {/* )
                  } */}
                  {/* <Router>
                    <nav>
                      <Link to="/terms-conditions">{item}</Link>
                    </nav>
                  </Router> */}
                  {/* <Link to="/terms-conditions">{item}</Link> */}
                  {/* {
                  item ==='Terms & Conditions' ? <Link to='/terms-conditions'>{item}</Link>:
                  item} */}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BottomListItems;
