import React, {useEffect} from "react";
import "./contact.scss";
import supportImage from '../../Assets/images/support.jpg';
function ContactUs() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="contact sec_py">
      <div className="container center">
        <h2 className="title">Contact Us</h2>
        <p>
          Contact us by writing to us at:
        </p>
        <p className="address">
          <span>RevAiv Health LLC FZ</span>
        </p>
        <p>
          Business Center 1,
        </p>
        <p>
          M Floor, The Meydan Hotel,
        </p>
        <p>
          Nad Al Sheba, Dubai, United Arab Emirates
        </p>
        <p>
          Or by emailing us at <a href="mailto:support@revaiv.app">support@revaiv.app</a>
        </p>

        <h2 className="title margin_top">
          Support
        </h2>
        <h4 className="title">Steps to Get Support from In App</h4>
        <ul className="contact_support">
          <li><span>1</span>Login with the exisitng account</li>
          <li><span>2</span>Go to the Support page from the side menu</li>
          <li className="img"><img src={supportImage} /></li>
          <li><span>3</span>Select Request Type from the drop down.</li>
          <li><span>4</span>Specify in the description field what support you need.</li>
          <li><span>5</span>Support team will receive your ticket and get back to you over your registered Email/Mobile as early as possible</li>
        </ul>
      </div>
    </div>
  );
}

export default ContactUs;
