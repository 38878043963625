import "./App.scss";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import ContactUs from "./Components/ContactUs";
import AboutUs from "./Components/AboutUs";
import FAQs from "./Components/FAQs";
import PatientsPage from "./Components/PatientsPage";
import SymptomsofPCOS from "./Components/SymptomsofPCOS";
import { HashRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import TermsAndConditions from "./Components/TandC";
import Privacy from "./Components/Privacy";
import DeleteAccount from "./Components/DeleteAccount";
import LandingPage from "./Components/LandingPage";
function App() {
  const isRouteExcluded = true
  return (
    <div className="App">
      <HashRouter>
        <Header />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/revaiv-for-patients" element={<PatientsPage />} />
          <Route path="/symptoms" element={<SymptomsofPCOS />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/landing-page" element={<LandingPage />} />
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
