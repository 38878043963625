import React from 'react'
import './rfp.scss'
// import Carousel from "react-material-ui-carousel";
import arrow from '../../Assets/svg/long arrow.svg'
import { Link } from 'react-router-dom';
// import sliderArrowLeft from '../../Assets/svg/slider-arrow-left.svg'
// import sliderArrowRight from '../../Assets/svg/slider-arrow-right.svg'
// import RandomIcon from '@@mui/icons-material/Random';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

function RFP() {

	const options = {
		items: 1,
		loop: true,
		dots: true,
		nav: true,
		autoplay: true,
		autoplayTimeout: 5000,
		smartSpeed: 1000
	}

    var items = [
      {
        name: "Chat with Health Buddy",
        description: "Whether you have questions, need motivation, or want to discuss your progress, we're here for you. What you’ll get-  Enjoy constant access to a support system that understands your journey and can offer assistance whenever you need it.",
      },
      {
        name: "Chat with Health Buddy",
				description: "Whether you have questions, need motivation, or want to discuss your progress, we're here for you. What you’ll get-  Enjoy constant access to a support system that understands your journey and can offer assistance whenever you need it.",
      },
      {
        name: "Chat with Health Buddy",
        description: "Whether you have questions, need motivation, or want to discuss your progress, we're here for you. What you’ll get-  Enjoy constant access to a support system that understands your journey and can offer assistance whenever you need it.",
      },
      {
        name: "Chat with Health Buddy",
        description: "Whether you have questions, need motivation, or want to discuss your progress, we're here for you. What you’ll get-  Enjoy constant access to a support system that understands your journey and can offer assistance whenever you need it.",
      },
      {
        name: "Chat with Health Buddy",
        description: "Whether you have questions, need motivation, or want to discuss your progress, we're here for you. What you’ll get-  Enjoy constant access to a support system that understands your journey and can offer assistance whenever you need it.",
      }
    ];
  return (
    <div className="rfp sec_py">
    <div className="container">
			<div className="main_title">
				<p>RevAiv for Patients</p>
				<h2>Personalized hormonal care begins here</h2>
			</div>
			<div className="rfp_slider">
      {/* <Carousel
        // fullHeightHover={false}
        navButtonsProps={{
          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
          style: {
            backgroundColor: "cornflowerblue",
            borderRadius: 0,
          },
        }}
        navButtonsWrapperProps={{
          // Move the buttons to the bottom. Unsetting top here to override default style.
          style: {
            bottom: "0",
            top: "unset",
          },
        }}
				NextIcon={<img src={sliderArrowRight} />} // Change the "inside" of the next button to "next"
				PrevIcon={<img src={sliderArrowLeft} />}
      >
				{items.map((item, i) => (
          <div className="rfp-card" key={i}>
            <div className="rfp-card-div">
              <h4>{item.name}</h4>
              <p>{item.description}</p>
							<div className="rfp-card-link">
								<Link to="#">
									<span>View more info</span><img src={arrow} alt="arrow" />
								</Link>
              </div>
            </div>
          </div>
        ))}
      </Carousel> */}


			<OwlCarousel className='owl-theme' {...options}>
				{items.map((item, i) => (
					<div className="rfp-card" key={i}>
						<div className="rfp-card-div">
							<h4>{item.name}</h4>
							<p>{item.description}</p>
							<div className="rfp-card-link">
								<Link to="#">
									<span>View more info</span><img src={arrow} alt="arrow" />
								</Link>
							</div>
						</div>
					</div>
				))}
			</OwlCarousel>


			</div>
		</div>
    </div>
  );
}

export default RFP;
