import React, { useState, useRef } from "react";
import './pcos.scss';
import womanImage from '../../Assets/images/PCOS.png'
import down from "../../Assets/svg/down arrow.svg";

function Pcos() {
  const listRef = useRef(null);
    const [listItem, setListItem] = useState("PCOS");
      const scroll = (type) => {
        if (listRef.current) {
           const list = listRef.current;
           const scrollWidth = list.scrollWidth - list.clientWidth;
          
           list.scrollTo({
             // right:0,
             left: type === 'right'?scrollWidth : 0,
             behavior: "smooth",
           });
          //  listRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
          // const container = listRef.current;
          // const maxScrollLeft = container.scrollWidth - container.clientWidth ;
          
          // container.scrollLeft = type === 'right'? maxScrollLeft : 0;
        }
      };
  return (
    <div className="pcos">
      <div className="scrollable">
			{/* <div className="container"> */}
				{/* <div className="pcos_tabs"> */}
					{/* <div className="left-arrow" onClick={() => scroll("left")}>
						<img src={down} alt="down arrow" />
					</div> */}
					<div className="list-scroll" ref={listRef}>
						{[
							"PCOS",
							"Endometriosis",
							"Thyroid Disorders",
							"Infertility",
						].map((item, index) => {
							return (
								<div
									onClick={() => setListItem(item)}
									className={`list-item ${item === listItem ? "selected" : ""}`}
									key={index}
								>
									{item}
								</div>
							);
						})}
					</div>
					{/* <div className="right-arrow" onClick={() => scroll("right")}>
						<img src={down} alt="down arrow" />
					</div> */}
				</div>
      {/* </div> */}
      <div className="pcos-content">
				<div className="container">
					<div className="row">
						<div className="col_6">
							<div className="pcos-content-left">
								<img src={womanImage} alt="woman" />
							</div>
						</div>
						<div className="col_6">
							<div className="pcos-content-right">
								<div className="top-text">
									{listItem === "PCOS" ? (
										<>
											<h2>PCOS <span>(Polycystic Ovary Syndrome)</span></h2>
										</>
									) : (
										"Coming Soon....."
									)}
								</div>
								{listItem === "PCOS" && (
									<h4>Workplace impact</h4>
								)}
								{listItem === "PCOS" && (
									<p>PCOS is tough on women. It affects not only their physical health but also their emotional well-being. Symptoms like acne and weight gain can lower their self-esteem. Fertility uncertainties add emotional strain, impacting relationships and overall well-being.</p>
								)}
								{listItem === "PCOS" && (
									<div className="pcos-frame">
										<strong>1 in 10</strong> <span>women are affected by PCOS</span>
									</div>
								)}
							</div>
						</div>
					</div>
					{listItem === "PCOS" && (
						<div className="pcos-btm-text">
							<p>RevAiv is currently designed to help with PCOS and its symptoms. More health conditions will be included soon.</p>
						</div>
					)}
				</div>
			</div>
    </div>
  );
}

export default Pcos
