import React from 'react'
import BottomListItems from './BottomListItems';

export default function BottomList() {
  return (
    <div className="btm-list-div">
      <BottomListItems type={"icons"} />
      <BottomListItems type={"about"} />
      <BottomListItems type={"support"} />
      <BottomListItems type={"more"} />
      <BottomListItems type={"social"} />
    </div>
  );
}
