import React from 'react'
import AppIntro from '../AppIntro';
import Pcos from '../PCOS';
import HowRevaivWorks from '../HowRevaivWorks';
import RFP from '../RevaivForPatients';
import Reviews from '../Reviews';
import MobileHealth from '../MobileHealth';
import TeamMeet from '../TeamMeat';
import AboutPage from '../AboutPage';

function Home() {
    // console.log('home')
  return (
    <div className="home">
      <AppIntro />
      <Pcos />
      <HowRevaivWorks />
      <RFP />
      <Reviews />
      <MobileHealth />
      <TeamMeet />
      <AboutPage />
    </div>
  );
}

export default Home
