import React, { useState } from "react";
import androidlogo from "../../Assets/svg/google.png";
import applelogo from "../../Assets/svg/apple.png";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

function Button(props) {

  const [openDialog, setOpenDialog] = useState(false);
  const modalDialog = ['Get Started', 'Download App'];

  const handleClickEvent = () => {
    if(modalDialog.includes(props.value)) {
      setOpenDialog(true)
    }
  }

  const handleClose = () => {
    setOpenDialog(false);
  }

  const dialogStyle = {
    textAlign:"center",
    marginBottom:"10px",
    marginTop:"10px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    padding:"20px"
  };
  return (
    <>
      <div className="btn-div 123">
        <button className={props.type} onClick={handleClickEvent}>{props.value}</button>
      </div>

       {/* Dialog Box */}
       <Dialog open={openDialog} fullWidth maxWidth="sm">
          <div style={{display:"flex", justifyContent:"end"}}>
          <CloseIcon onClick={handleClose}/>
          </div>
          <div className="btn-div" style={dialogStyle}>
            <div class="list-item icon-list-item" style={{margin:"0px 10px"}}>
              <a href="https://play.google.com/store/apps/details?id=app.revaiv" target="__blank"><img src={androidlogo} alt="google-play-store"/></a>
            </div>
            <div class="list-item icon-list-item" style={{margin:"0px 10px"}}>
              <a href="https://apps.apple.com/app/revaiv/id6477476967" target="__blank"><img src={applelogo} alt="apple-store" /></a>
            </div>
          </div>
      </Dialog>
    </>
  );
}

export default Button;
