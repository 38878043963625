import React from "react";
import "./teammeet.scss";
import team from "../../Assets/svg/team-icon.svg";
import teamMob from "../../Assets/svg/team-mob.svg";
// import team from '../../Assets/team2.png'
import Button from "../Modules/Button";
import { Link } from 'react-router-dom';
function TeamMeet() {
  return (
    <div className="team-meet sec_py">
    	<div className="container">
    		<div className="row">
					<div className="col_6">
						<div className="team_text">
							<div className="main_title">
								<p>Meet our team</p>
								<h2>Dedicated specialists who genuinely care.</h2>
							</div>
							<img className="mob_only" src={teamMob} alt="team" />
							<p>Our team is here to ensure your symptoms improve and your concerns are heard. Together, we'll discover what works best for you.</p>
							<Link to="/about-us"><Button type={"contained"} value={"Meet the Team"}/></Link>
						</div>
					</div>
					<div className="col_6 des_only">
						<div className="team-bg">
							<img src={team} alt="team" />
						</div>
					</div>
				</div>
      </div>
    </div>
  );
}

export default TeamMeet;
