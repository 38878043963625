import React,{useState} from "react";
import "./reviews.scss";
import Carousel from "react-material-ui-carousel";
import arrow from "../../Assets/svg/long arrow.svg";
// import RandomIcon from '@@mui/icons-material/Random';
import stars from '../../Assets/svg/stars icon.svg'
import sliderArrowLeft from '../../Assets/svg/slider-arrow-left.svg'
import sliderArrowRight from '../../Assets/svg/slider-arrow-right.svg'
import OwlCarousel from 'react-owl-carousel';
import { Height } from "@mui/icons-material";
// import 'owl.carousel/dist/assets/owl.carousel.css';

function Reviews() {
		
		const options = {
			loop: true,
			margin: 20,
			dots: true,
			nav: true,
			autoplay: true,
			autoplayTimeout: 5000,
			smartSpeed: 1000,
			responsive: {
				0: {
					items: 1
				},
				951: {
					items: 2
				},
				1151: {
					items: 3
				}
			}
		}

		const items = [
      {
        name: "Sandra, 28",
        review:
          "This has made so many symptoms manageable. This has been so easy to do and have actually seen results in my dealings with the side effects of menopause. The team was able to answer questions and I didn't feel rushed and under a time limit. I could just send my questions and know they would be answered.",
      },
      {
        name: "Casey, 37",
        review:
          "Fits into my busy life. This was a helpful program at the time for my hectic schedule. And the medication was helpful in alleviating my early menopausal symptoms.",
      },
      {
        name: "Wendy, 55",
        review:
          "The care team makes sure you feel important. The way I feel so supported and like I can ask anything with ease is very important to me. I also enjoy the easy access to the care team and the quick responses.",
      },
      {
        name: "Sandra, 28",
        review:
          "This has made so many symptoms manageable. This has been so easy to do and have actually seen results in my dealings with the side effects of menopause. The team was able to answer questions and I didn't feel rushed and under a time limit. I could just send my questions and know they would be answered.",
      },
      {
        name: "Casey, 37",
        review:
          "Fits into my busy life. This was a helpful program at the time for my hectic schedule. And the medication was helpful in alleviating my early menopausal symptoms.",
      },
      {
        name: "Wendy, 55",
        review:
          "The care team makes sure you feel important. The way I feel so supported and like I can ask anything with ease is very important to me. I also enjoy the easy access to the care team and the quick responses.",
      },
		];

    const [list, setList] = useState([
      {
        name: "Sandra, 28",
        review:
          "This has made so many symptoms manageable. This has been so easy to do and have actually seen results in my dealings with the side effects of menopause. The team was able to answer questions and I didn't feel rushed and under a time limit. I could just send my questions and know they would be answered.",
      },
      {
        name: "Casey, 37",
        review:
          "Fits into my busy life. This was a helpful program at the time for my hectic schedule. And the medication was helpful in alleviating my early menopausal symptoms.",
      },
      {
        name: "Wendy, 55",
        review:
          "The care team makes sure you feel important. The way I feel so supported and like I can ask anything with ease is very important to me. I also enjoy the easy access to the care team and the quick responses.",
      },
    ]);
  {/* var items = [
    {
      name: "Chat with Health Buddy",
      description:
        "Whether you have questions, need motivation, or want to discuss your progress, we're here for you. What you’ll get-  Enjoy constant access to a support system that understands your journey and can offer assistance whenever you need it.",
    },
  ]; */}
  return (
    <div className="reviews sec_py">
    	<div className="container">
      <div className="main_title">
				<p>Reviews</p>
				<h2>What feeling better feels like</h2>
			</div>
			<div className="reviews_slider">
      {/* <Carousel
        fullHeightHover={false}
        navButtonsProps={{
          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
          style: {
            backgroundColor: "cornflowerblue",
            borderRadius: 0,
          },
        }}
        navButtonsWrapperProps={{
          // Move the buttons to the bottom. Unsetting top here to override default style.
          style: {
            bottom: "0",
            top: "unset",
          },
        }}
				NextIcon={<img src={sliderArrowRight} />} // Change the "inside" of the next button to "next"
				PrevIcon={<img src={sliderArrowLeft} />}
        // NextIcon={<img src="http://random.com/next" />}
        // PrevIcon={<img src="http://random.com/prev" />}
      >
        {/* <div className="list"> *//*}
        {[1,2,3,4,5].map((item, index) => {
          return (
            <div key={index} className="carousel-card">
         
          
                {list.map((i, index2) => {
                  return (
                <div className="list-item" key={index2}>
                  <h4>{i.name}</h4>
                  <div className="stars">
                    <img src={stars} alt="stars" />
                  </div>
                  <p>{i.review}</p>
                </div>
                 );
                })}
              
             
            </div>
          );
        })}
        {/* </div> *//*}
      </Carousel> */}

			<OwlCarousel className='owl-theme' {...options}>
				{items.map((item, i) => (
					<div className="carousel-card">
						<div className="list-item">
							<h4>{item.name}</h4>
							<div className="stars">
								<img src={stars} alt="stars" />
							</div>
							<p>{item.review}</p>
						</div>
					</div>
				))}
			</OwlCarousel>
			
			</div>
    </div>
    </div>
  );
}

export default Reviews;
