import React, { useState, useEffect } from "react";
import "./faqs.scss";
import faqArrow from "../../Assets/svg/faq-arrow.svg";
import {	Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from 'react-accessible-accordion';

function FAQs() {

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const [list, setList] = useState([
		{ 
			question: "What is PCOS, and how does it affect women's health?",
			answer: "PCOS, or Polycystic Ovary Syndrome, is a hormonal disorder that affects women of reproductive age. It can lead to various symptoms, including irregular periods, hormonal imbalances, and potential fertility issues.",
		}, {
			question: "Can Polycystic Ovary Syndrome (PCOS) be cured?",
			answer: "No, there is no cure for PCOS as it is a lifelong condition. Nevertheless, it is possible to reverse its symptoms, including weight gain, hair loss, excess hair, acne, and irregular periods, through long-term lifestyle-based changes. Studies have indicated that women with PCOS who seek treatment from multiple healthcare providers have a better likelihood of effectively managing their condition.",
		}, {
			question: "Will you help treat weight gain, hair loss, excess hair, acne and/or irregular periods?",
			answer: "We provide a holistic approach to help improve all your symptoms. We also check for mood swings and mental health concerns such as stress, anxiety, and depression because women with PCOS are more likely to experience these issues.",
		}, {
			question: "I’m already trying another treatment and/or have a doctor. Can I still do this program?",
			answer: "It's advisable to involve your doctor in this process, Track your symptoms in the RevAiv app, and share your health insights with your doctor on your next appointment. This will complement your treatment and the doctor will know minute details about your health in between the appointments.",
		}, {
			question: "Will this program put me on oral contraceptives?",
			answer: "Our approach centers on making lifestyle changes that empower you to effectively manage PCOS in the long run. Numerous patients discover that they can successfully manage their condition without the need for medication. We do not prescribe any kind of medicines at RevAiv.",
		}, {
			question: "How long does it take to see results?",
			answer: "The timeline for improvement varies depending on your symptoms. While you might notice positive changes within a month, we typically recommend a minimum of 6 months. This timeframe allows you to acquire the skills and knowledge needed to effectively manage your condition sustainably.",
		}, {
			question: "How can RevAiv help me manage my condition?",
			answer: "RevAiv provides a range of tools and resources to help you manage your PCOS effectively. This includes personalized health insights, nutrition and meal planning, exercise routines, and community support.",
		}, {
			question: "Is the RevAiv app suitable for all women with PCOS?",
			answer: "Yes, the Revaiv app is designed to cater to the needs of women at different stages of their PCOS journey, whether they're newly diagnosed or have been living with PCOS for some time.",
		}, {
			question: "Can I track my menstrual cycles using the app?",
			answer: "Yes, the app includes cycle-tracking features, allowing you to monitor your menstrual cycles.",
		}, {
			question: "Can I get information about the latest research and developments in PCOS management?",
			answer: "Yes, our app includes an educational resources section with articles and expert insights to keep you informed about the latest research and developments in PCOS management.",
		}, {
			question: "How do I download the RevAiv app? ",
			answer: "You can download the RevAiv app from the [App Store/Google Play Store], and it is available for both iOS and Android devices.",
		}, {
			question: "How can I create an account on the RevAiv app?",
			answer: "To create an account, simply download the app, open it, and follow the on-screen prompts to set up your profile. You will need to provide basic information to get started",
		}, {
			question: "I'm new to the RevAiv app. How can I get started and make the most of it?",
			answer: "To get started, download the app, create an account, and explore the various features available. Begin by setting up your profile, inputting your PCOS-related information, and then take a self-assessment to analyze your symptoms, book a consultation with the health coach and get personalized care plans and community support (coming soon) to kickstart your RevAiv journey",
		}, {
			question: "What is the first step in using the app to manage my PCOS effectively?",
			answer: "The first step is to complete your profile and input relevant information about your PCOS condition in the Self-assessment. Once your profile is set up, you can start exploring personalized recommendations and utilizing the available resources to manage your PCOS effectively.",
		}, {
			question: "How do I delete my account if I no longer wish to use the app?",
			answer: 'You can delete your account by going to the "Account Settings" or "Profile" section within the app. Follow the prompts to permanently delete your account.',
		}, {
			question: "Who is behind the development of the RevAiv app?",
			answer: "Our app is developed by a team of healthcare professionals, nutritionists, fitness experts, and software engineers dedicated to supporting individuals with PCOS.",
		}, {
			question: "Can I contact the app's support team if I have questions or need assistance?",
			answer: 'Yes, you can reach out to our support team through the "Contact Us" section of the app for any questions or assistance you may need.',
		}, {
			question: "Is RevAiv free to use?",
			answer: "Currently, all the features are free. In upcoming versions, the app will offer both free and premium features. Some features will be available at no cost, while others may require a subscription for access.",
		}, {
			question: "What are the key features and functionalities of the RevAiv app?",
			answer: "The RevAiv app offers self-assessment of PCOS evaluation, daily progress tracking,  cycle tracking, personalized health insights and educational resources, and a personalized health dashboard. These features are designed to help you manage your PCOS effectively.",
		}, {
			question: "Is there a notification feature in the app to remind me of important health routines and appointments?",
			answer: "Yes, the app includes a notification feature to help you stay on track with your health routines and medications. You can set reminders as needed.",
		}, {
			question: "Can I access the app on multiple devices like my smartphone and tablet?",
			answer: "Yes, you can access the RevAiv app on multiple devices. Simply log in with your account credentials on each device to sync your data and progress.",
		}, {
			question: "How is my health data and information protected in the RevAiv app?",
			answer: "We take the security and privacy of your data seriously. Your health data is stored securely, and we follow strict privacy guidelines. Please review our Privacy Policy for more details.",
		}, {
			question: "Is my data shared with third parties, advertisers, or researchers without my consent?",
			answer: "We do not share your data with third parties, advertisers, or researchers without your explicit consent. Your privacy is our priority.",
		}, {
			question: "What should I do if I encounter technical issues with the app, such as crashes or errors?",
			answer: 'If you experience technical issues, please reach out to our technical support team through the "Contact Us" section in the app. Our support team will assist you in resolving the problem.',
		}, {
			question: "How often is the app updated with new content and features?",
			answer: "We regularly update the app with new content and features to provide you with the latest information and tools to manage your PCOS effectively.",
		}, {
			question: "Is the app available in multiple languages, or is it limited to a specific language?",
			answer: "Currently, RevAiv is available in English language. However Arabic version will be out soon. We will notify you once it is available.",
		}, {
			question: "Is RevAiv covered by insurance?",
			answer: "No, RevAiv is not covered by insurance",
		}, {
			question: "What is the daily progress score?",
			answer: "It's a score that Revaiv experts calculate based on your daily progress tracking like exercise and diet. It provides a comparison of your score with the recommended score.",
		}, {
			question: "What is my severity level?",
			answer: "It's based on the symptoms you selected during your initial assessment. It describes how severe your disease was when you started your journey with us.",
		}, {
			question: "Why should I track my daily progress?",
			answer: "Tracking your daily progress helps you manage your PCOS journey better by providing valuable insights into different aspects of your health, such as changes in symptoms, sleeping patterns, and energy levels.",
		}, {
			question: "What happens if I miss tracking my progress any day?",
			answer: "You can track the progress of the missed day first and then the present day. Progress tracking is important for managing your PCOS journey.",
		}, {
			question: "How does cycle tracking work?",
			answer: "You add the starting and ending date of your menstrual cycle to keep a record of your period dates and predict your next cycle date.",
		}, {
			question: "Why do I need to log my past 3 cycles?",
			answer: "It helps Revaiv understand how your flow works, identify severity and predict your future cycles.",
		}, {
			question: "How can I set reminders?",
			answer: "Go to the reminder section and set the time for medication, sleep, and water intake.",
		}, {
			question: "What is cycle date, flow type, and cycle duration?",
			answer: "Cycle date is the day of your period, flow type indicates the intensity of blood discharge, and cycle duration is the number of days between periods.",
		}, {
			question: "Do I need to log medication if not prescribed?",
			answer: "No, only log medication if prescribed by a doctor.",
		},
	]);

return (
	<>
	<div className="faqs_sec sec_py">
		<div className="container">
			<div className="main_title">
				<h2>FAQs</h2>
			</div>
			<Accordion>
				{list.map((item) => {
					return (
						<AccordionItem>
							<AccordionItemHeading>
								<AccordionItemButton>
									<h4>{item.question}</h4><img src={faqArrow} alt="faqArrow" />
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<p>{item.answer}</p>
							</AccordionItemPanel>
						</AccordionItem>
					);
				})}
			</Accordion>
		</div>
	</div>
	</>
  );
}

export default FAQs;
