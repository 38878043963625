import React, { useEffect, useState } from "react";
import Button from "../Modules/Button";
import Input from "../Modules/Input";
import './footer.scss'
import BottomList from "./BottomList";
import DownloadPopup from './DownloadPopup';
import { useLocation, useNavigate } from 'react-router-dom';
import OutlinedInput from "@mui/material/OutlinedInput";

function Footer() {
	const navigate = useNavigate();
	const location = useLocation();
	const [email, setEmail] = useState('');
	const [error, setError] = useState({});

	const handleInputChange = (event) => {
		setError({});
		setEmail(event.target.value);
	};

	const handleClick = () => {

		if(email == '') {
			setError('please enter email');
		} else {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if(emailRegex.test(email) == false) {
				setError('please enter valid email');
			}

			if(emailRegex.test(email) == true) {
				navigate("/landing-page", { state: { email: email } });
			}

		}
	};
  return (
	<>
    <div className="footer" style={{ display: (location.pathname == '/landing-page' ? 'none' :'block')}}>
		<div className="container">
			<div className="footer_started">
				<div className="main_title">
					<h2>Let's get started!</h2>
				</div>
				<Button value={"Download App"} type={"contained"} />
			</div>
			<div className="footer_newsletter">
				<h4>Sign up for our Newsletter to <strong>Get Experts’ Advice</strong></h4>
				<div className="footer_newsletter_form">
				<div className='input-div'>
					<OutlinedInput
						placeholder="Your Email"
						id="outlined-adornment-weight"
						// endAdornment={<InputAdornment position="end">kg</InputAdornment>}
						aria-describedby="outlined-weight-helper-text"
						inputProps={{
						"aria-label": "weight",
						}}
						onChange={handleInputChange}
					/>
					</div>
					<div className="btn-div">
					 <button className={'contained'} onClick={handleClick}>{"Sign me up!"}</button>
					</div>
				</div>
				{(Object.keys(error).length > 0) ? <p className="error">{error}</p> : '' }
			</div>
			<BottomList />
			<div className="copyright">© {new Date().getFullYear()} RevAiv Health LLC-FZ | All Rights Reserved.</div>
		</div>
    </div>

	<DownloadPopup />
	</>
  );
}

export default Footer;
