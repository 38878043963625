import React from 'react'
import './intro.scss';
import woman from '../../Assets/images/woman-image.jpg'
import android from '../../Assets/svg/andriodapp.svg';
import apple from '../../Assets/svg/apple black.svg'
import arrow from '../../Assets/svg/arrow direction.svg'
import { Link } from 'react-router-dom';
function AppIntro() {
  return (
    <div className="intro sec_py">
      <div className="container">
        <div className="intro_row">
			<div className="intro_img">
			<h2 className="mob_only">A personalized app to manage your complex hormonal issues and symptoms</h2>
            <img src={woman} alt="woman img" />
          </div>
          <div className="intro_txt">
			<h2 className="des_only">A personalized app <br />to manage your <br />complex hormonal issues and symptoms</h2>
			<p>Get personalized care, track your <br />symptoms and share progress with your <br />doctor, all in one space!</p>
			<div className="app-icons">
				<div className="app-icon android">
					<Link to="https://play.google.com/store/apps/details?id=app.revaiv" target='__blank'><img src={android} alt='Download Revaiv from Google Play Store' title='Download Revaiv from Google Play Store' /></Link>
				</div>
				<div className="app-icon">
					<Link to="https://apps.apple.com/app/revaiv/id6477476967" target='__blank'><img src={apple} alt='Download Revaiv from Apple App Store' title='Download Revaiv from Apple App Store' /></Link>
				</div>
			</div>
          </div>
        </div>
		<div className='intro_arrow'>
          <img src={arrow}/>
        </div>
      </div>
    </div>
  );
}

export default AppIntro
