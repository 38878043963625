import React, {useEffect} from "react";
import "./tnc.scss";
import { Link } from 'react-router-dom';
function TermsAndConditions() {
	
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

  return (
		<div className="tnc tnc_privacy sec_py">
			<div className="container">
				<h1>Terms and Conditions</h1>
				<p>Welcome to RevAiv Health LLC FZ ("RevAiv Health," "we," or "us"). These Terms and Conditions ("Terms") govern your access to and use of the RevAiv Health website located at https://revaiv.app/ (the "Website") and the RevAiv Health mobile application (the "App"). By accessing or using the Website and/or App, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the Website and/or App.</p>
				<h4>Use of Website and App</h4>
				<p>1.1 License: Subject to these Terms, RevAiv Health grants you a limited, non-exclusive, non-transferable, and revocable license to use the Website and App for your personal, non-commercial use.</p>
				<p>- Modify, adapt, translate, or create derivative works of the Website or App;</p>
				<p>- Use the Website or App for any unlawful purpose or in violation of these Terms;</p>
				<p>- Use the Website or App for any unlawful purpose or in violation of these Terms;</p>
				<p>- Remove or alter any copyright, trademark, or other proprietary rights notices from the Website or App.</p>
				<h4>User Accounts</h4>
				<p>2.1 Registration: In order to access certain features of the Website or App, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
				<p>2.2 Security: You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify RevAiv Health immediately of any unauthorized use of your account or any other breach of security.</p>
				<h4>Content</h4>
				<p>3.1 Ownership: All content, including text, graphics, images, and other materials, made available through the Website or App ("Content") is the property of RevAiv Health or its licensors and is protected by copyright, trademark, and other intellectual property laws.</p>
				<p>3.2 Use of Content: You may access and use the Content solely for your personal, non-commercial use. You may not reproduce, distribute, modify, or create derivative works of the Content without the express written consent of RevAiv Health.</p>
				<h4>Privacy</h4>
				<p>4.1 Privacy Policy: Your use of the Website and App is subject to RevAiv Health's Privacy Policy, which is incorporated by reference into these Terms. By using the Website and/or App, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy.</p>
				<h4>Disclaimer</h4>
				<p>5.1 No Medical Advice: <strong>The Content provided on the Website and App is for informational purposes only and is not intended to be a substitute for professional medical advice, diagnosis, or treatment.</strong> Always seek the advice of your physician or other qualified healthcare provider with any questions you may have regarding a medical condition.</p>
				<p>5.2 No Warranty: The Website and App are provided on an "as is" and "as available" basis without warranties of any kind, either express or implied. RevAiv Health does not warrant that the Website or App will be error-free or uninterrupted or that any defects will be corrected.</p>
				<h4>Limitation of Liability</h4>
				<p>6.1 Exclusion of Damages: In no event shall RevAiv Health be liable for any indirect, incidental, special, consequential, or punitive damages, including lost profits, lost data, or business interruption, arising out of or in connection with your use of the Website and/or App, whether based on warranty, contract, tort (including negligence), or any other legal theory.</p>
				<h4>Indemnification</h4>
				<p>7.1 Indemnity: You agree to indemnify, defend, and hold harmless RevAiv Health and its officers, directors, employees, agents, and affiliates from and against any and all claims, damages, liabilities, costs, and expenses (including attorneys' fees) arising out of or in connection with your use of the Website and/or App or any violation of these Terms.</p>
				<h4>Changes to Terms</h4>
				<p>8.1 Modification: RevAiv Health reserves the right to modify or update these Terms at any time without prior notice. Any changes to these Terms will be effective immediately upon posting the revised Terms on the Website and/or App. Your continued use of the Website and/or App following the posting of any changes constitutes acceptance of those changes.</p>
				<h4>Governing Law and Jurisdiction</h4>
				<p>9.1 Governing Law: These Terms shall be governed by and construed in accordance with the laws of the United Arab Emirates.</p>
				<p>9.2 Jurisdiction: Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of the United Arab Emirates.</p>
				<h4>Contact Us</h4>
				<p>10.1 If you have any questions or concerns about these Terms, please contact us at <Link to="mailto:health@revaiv.app">health@revaiv.app</Link>.</p>
				<p>By using the Website and/or App, you acknowledge that you have read, understood, and agree to be bound by these Terms.</p>

			</div>
		</div>
  );
}

export default TermsAndConditions;
