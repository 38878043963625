import React from "react";
import "./symptoms.scss";
import Button from "./Button.js";
import symptomsIrregular from "../../Assets/svg/IrregularPeriods.svg";
import symptomsHair from "../../Assets/svg/HairLoss.svg";
import OvulatoryDysfunction from "../../Assets/svg/OvulatoryDysfunction.svg";
import PelvicPain from "../../Assets/svg/PelvicPain.svg";
import HeadachesMigraines from "../../Assets/svg/HeadachesMigraines.svg";
import SkinIssues from "../../Assets/svg/SkinIssues.svg";
import Hirsutism from "../../Assets/svg/Hirsutism.svg";
import WeightGain from "../../Assets/svg/WeightGain.svg";
import SkinDarkening from "../../Assets/svg/SkinDarkening.svg";
import Fatigue from "../../Assets/svg/Fatigue.svg";
import DigestiveIssues from "../../Assets/svg/DigestiveIssues.svg";
import HighBloodPressure from "../../Assets/svg/HighBloodPressure.svg";
import Acne from "../../Assets/svg/Acne.svg";
import InsulinResistance from "../../Assets/svg/InsulinResistance.svg";
import MoodDisorders from "../../Assets/svg/MoodDisorders.svg";
import SleepDisturbances from "../../Assets/svg/SleepDisturbances.svg";
import PainDiscomfort from "../../Assets/svg/PainDiscomfort.svg";
import AbnormalUterineBleeding from "../../Assets/svg/AbnormalUterineBleeding.svg";

function SymptomsofPCOS() {

return (
	<>
	<div className="symptoms_sec sec_py">
		<div className="container">
			<div className="main_title">
				<h2>Symptoms of PCOS</h2>
				<p>Recognize your symptoms and take informed steps towards better symptom management. Explore the symptoms and take charge of your health.</p>
			</div>
			<div className="row">
				<div className="col_4">
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={symptomsIrregular} />
						</div>
						<div className="symptoms_box_title">
							<h2>Irregular Periods</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Irregular periods mean menstrual cycles that occur at different times. Periods or no periods? You are always in this confusion. Normally the cycle is a 21- to 35-day cycle. But if you have PCOS this may not be your “normal” because your cycle length could vary.</li>
								<li>Irregular periods can impact fertility and may lead to issues in getting pregnant.</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={symptomsHair} />
						</div>
						<div className="symptoms_box_title">
							<h2>Hair Loss</h2>
							<h4>(Alopecia)</h4>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Hair loss, or alopecia, is the thinning of hair on the scalp and can affect hair volume. One can feel significant loss in hair.</li>
								<li>Hair loss causes distress and loss of confidence</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={OvulatoryDysfunction} />
						</div>
						<div className="symptoms_box_title">
							<h2>Ovulatory Dysfunction</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Ovulatory dysfunction is irregular or absent ovulation, which can make it challenging to conceive. It is one of the main causes of infertility among women with PCOS.</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={PelvicPain} />
						</div>
						<div className="symptoms_box_title">
							<h2>Pelvic Pain</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Pelvic pain can occur in some individuals with PCOS, often associated with the presence of cysts on the ovaries.</li>
								<li>Persistent or severe pelvic pain may require medical evaluation (ultrasound) and can impact a person's overall well-being.</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={HeadachesMigraines} />
						</div>
						<div className="symptoms_box_title">
							<h2>Headaches or Migraines</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Some women may experience headaches or migraines because of PCOS</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={SkinIssues} />
						</div>
						<div className="symptoms_box_title">
							<h2>Skin Issues</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Beyond acne, skin issues like skin tags, dark patches, and excessive oiliness are also present in some individuals with PCOS.</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="col_4">
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={Hirsutism} />
						</div>
						<div className="symptoms_box_title">
							<h2>Hirsutism</h2>
							<h4>(Excess Hair Growth)</h4>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Hirsutism is the growth of excessive, thick, and dark hair in areas such as the face, chest, and back. It's a common PCOS symptom due to hormonal imbalances.</li>
								<li>It is difficult to deal with, as it can cause emotional distress and badly affects self-image.</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={WeightGain} />
						</div>
						<div className="symptoms_box_title small_title">
							<h2>Weight Gain and Difficulty Losing Weight</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Many women with PCOS experience unexplained weight gain, particularly around the belly. In PCOS, you could be lean and feel you’re gaining fast, or you find it hard to lose weight although you exercise and eat a healthy diet.</li>
								<li>Weight gain increases the risk of various health issues, including insulin resistance and type 2 diabetes.</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={SkinDarkening} />
						</div>
						<div className="symptoms_box_title">
							<h2>Skin Darkening</h2>
							<h4>(Acanthosis Nigricans)</h4>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Acanthosis nigricans is the darkening and thickening of skin, often occurring in skin folds and creases. It is often linked with insulin resistance and obesity.</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={Fatigue} />
						</div>
						<div className="symptoms_box_title">
							<h2>Fatigue</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Many women with PCOS complain of feeling unusually fatigued or lacking energy, which affects their motivation to do almost anything.</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={DigestiveIssues} />
						</div>
						<div className="symptoms_box_title">
							<h2>Digestive Issues</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Digestive problems, such as bloating, constipation, or diarrhea, may be more common in individuals with PCOS.</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={HighBloodPressure} />
						</div>
						<div className="symptoms_box_title">
							<h2>High Blood Pressure</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Hypertension, or high blood pressure, is a potential symptom or health effect associated with PCOS, particularly in cases of insulin resistance.</li>
								<li>High blood pressure increases the risk of cardiovascular diseases and other health complications.</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="col_4">
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={Acne} />
						</div>
						<div className="symptoms_box_title">
							<h2>Acne</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>PCOS-related acne is mostly frequent, moderate to severe acne on the face, chest, or back. It occurs due to hormonal imbalance in women, research suggests that 50% of women in their 20s and 25% of women in their 40s get hormonal acne.</li>
								<li>Acne may leave scars and affect self-esteem. It's a concern for many women with PCOS, but it can be managed.</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={InsulinResistance} />
						</div>
						<div className="symptoms_box_title">
							<h2>Insulin Resistance</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Insulin resistance is a condition in which the body's cells don't respond effectively to insulin which causes elevated blood sugar levels, increasing the risk of type 2 diabetes.</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={MoodDisorders} />
						</div>
						<div className="symptoms_box_title">
							<h2>Mood Disorders</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Most women with PCOS may experience mood disorders, such as depression and anxiety. One minute you’re happy, the next minute you’re stressed.</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={SleepDisturbances} />
						</div>
						<div className="symptoms_box_title">
							<h2>Sleep Disturbances</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Women with PCOS may have sleeping issues like insomnia and sleep apnea. Poor sleep quality can lead to hormonal imbalance,  mood disorders, weight gain, and increased risk of chronic health conditions.</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={PainDiscomfort} />
						</div>
						<div className="symptoms_box_title small_title">
							<h2>Pain or Discomfort During Intercourse</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>Pain or discomfort during sexual intercourse can be associated with PCOS, potentially due to hormonal imbalances or gynecological factors.</li>
							</ul>
						</div>
					</div>
					<div className="symptoms_box">
						<div className="symptoms_box_img">
							<img src={AbnormalUterineBleeding} />
						</div>
						<div className="symptoms_box_title small_title">
							<h2>Abnormal Uterine Bleeding</h2>
						</div>
						<Button />
						<div className="symptoms_box_contant">
							<ul>
								<li>PCOS can cause abnormal uterine bleeding, such as heavy or prolonged menstruation, apart from irregular periods. It can lead to anemia and reduced quality of life.</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="symptoms_bg"/>
	</div>
	</>
  );
}

export default SymptomsofPCOS;
