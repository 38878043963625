import React, { useState, useEffect } from "react";
import "./aboutus.scss";
import phone from "../../Assets/svg/phonetrack.svg";
import db from "../../Assets/svg/dashboard.svg";
import care from "../../Assets/svg/customercare.svg";
import monitor from "../../Assets/svg/monitor.svg";
import mission from "../../Assets/svg/mission.svg";
import vision from "../../Assets/svg/vision.svg";
import ali from "../../Assets/images/ali.jpg";
import latouna from "../../Assets/images/latouna.jpg";
import rabyya from "../../Assets/images/rabyya.jpg";
import divya from "../../Assets/images/divya.jpg";
import pressila from "../../Assets/images/pressila.jpg";
import areej from "../../Assets/images/areej.jpg";
function AboutUs() {

useEffect(() => {
	window.scrollTo(0, 0)
}, [])
const [list, setList] = useState([
	{
	icon: care,
	content: "Strengthen the relationship between you & your doctor.",
	},
	{
	icon: monitor,
	content: "Keeping track of Symptoms and making adjustments.",
	},
	{
	icon: phone,
	content: "Embracing positive Lifestyle shifts.",
	},
	{
	icon: db,
	content: "Finding support from peers who are on a similar journey.",
	},
]);

const [missionlist, setListMission] = useState([
	{
	icon: mission,
	name: "Our Mission",
	content: "To empower women with PCOS and other hormonal issues by managing their symptoms with holistic care and a supportive community, enabling a path towards a healthy lifestyle.",
	},
	{
	icon: vision,
	name: "Our Vision",
	content: "To become the go-to application for individuals with complex hormonal issues allowing them to be the healthiest version of themselves.",
	},
]);

const [teamlist, setListTeam] = useState([
	{
	icon: ali,
	name: "Dr. Ali Aldibbiat ",
	content: "Consultant Endocrinologist and Adjunct Associate Professor of Endocrinology",
	},
	{
	icon: latouna,
	name: "Dr. Latouna Mourad",
	content: "Reproductive Endocrinologist",
	},
	{
	icon: rabyya,
	name: "Dr. Rabyya Fatima",
	content: "DPT, NPT* Neuro Physical therapist* Health and Fitness coach",
	},
	{
	icon: divya,
	name: "Divya R. Sanglikar",
	content: "MSc Nutrition & Dietetics ISSN Sports Nutritionist",
	},
	{
	icon: pressila,
	name: "Pressila Saliba",
	content: "Licensed Dietitian, LD",
	},
	{
	icon: areej,
	name: "Areej Chaudary",
	content: "Certified Dietitian",
	},
]);

return (
	<>
    <div className="about_info_sec sec_py">
		<div className="container">
			<div className="about_info">
				<div className="main_title">
					<h2>About Us</h2>
					<h1>Our Philosophy</h1>
				</div>
				<p>Welcome to RevAiv, a dedicated space for those navigating the journey with Polycystic Ovary Syndrome (PCOS). At RevAiv, we understand that PCOS is more than a medical condition; it's a complex puzzle of physical, emotional, and social issues. We recognize the daily obstacles, from irregular periods to hormonal imbalances, persistent weight concerns, fertility issues, and whatnot.</p>
				<p>What sets RevAiv apart is our commitment to addressing not only the medical aspects but also the holistic well-being of individuals dealing with PCOS. The challenges of PCOS extend beyond the symptoms – there's the isolation and the need for understanding from those who've been there.</p>
				<p>RevAiv is more than a resource; it's a community-driven initiative. Our goal is to provide a welcoming space where individuals dealing with PCOS can connect, find trusted information, and receive the support they need. Together, we aim to revive physical health and the spirit, fostering a community of strength, resilience, and understanding.</p>
			</div>
		</div>
	</div>
	<div className="about_core sec_py">
		<div className="container">
			<div className="main_title">
				<h2>Our Core Values</h2>
			</div>
			<div className="row">
				{list.map((item, index) => {
					return (
						<div key={index}className="col_3">
							<div className="about_core_card">
								<div className="about_core_card_img">
									<img src={item.icon} alt="icon" />
								</div>
								<p>{item.content}</p>
							</div>
						</div>
					);
				})} 
			</div>
		</div>
	</div>
	<div className="about_mission sec_py">
		<div className="container">
			<div className="row">
				{missionlist.map((item, index) => {
					return (
						<div key={index}className="col_6">
							<div className="about_mission_card">
								<div className="about_mission_card_img">
									<img src={item.icon} alt="icon" />
								</div>
								<h2>{item.name}</h2>
								<p>{item.content}</p>
							</div>
						</div>
					);
				})} 
			</div>
		</div>
	</div>
	<div className="about_team sec_py">
		<div className="container">
			<div className="main_title">
				<h2>Meet our Clinical Team</h2>
			</div>
			<div className="row">
				{teamlist.map((item, index) => {
					return (
						<div key={index}className="col_3">
							<div className="about_team_card">
								<div className="about_team_card_img">
									<img src={item.icon} alt="icon" />
								</div>
								<h4>{item.name}</h4>
								<p>{item.content}</p>
							</div>
						</div>
					);
				})} 
			</div>
		</div>
	</div>
	</>
  );
}

export default AboutUs;
