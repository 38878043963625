import React, { useState } from "react";
import "./workModel.scss";
import signup from "../../Assets/svg/signup-icon.svg";
import specilist from "../../Assets/svg/specialist-icon.svg";
import track from "../../Assets/svg/track-icon.svg";
import Button from "../Modules/Button";


function HowRevaivWorks() {
  const [cardList, setCardList] = useState([
    {
      icon: signup,
      title: "Sign up and Create a health Profile",
      content:
        "Create your health profile, add your medical history, symptoms & daily progress.",
    },
    {
      icon: specilist,
      title: "Get personalized care from a certified specialist",
      content:
        "We'll customize a care plan based on your medical history and symptoms.",
    },
    {
      icon: track,
      title: "Track your progress to manage your care efficacy ",
      content:
        "Regularly monitor your symptoms and progress to share with your healthcare provider assisting in effective care management.",
    },
  ]);
  return (
		<div className="work-model sec_py">
    	<div className="container">
				<div className="main_title">
					<p>Get started with us</p>
					<h2>How RevAiv works</h2>
				</div>
				<div className="row">
					{cardList.map((item, index) => {
						return (
							<div className="col_4" key={index}>
								<div className="card-icon">
									<img src={item.icon} />
								</div>
								<div className="card-text">
									<div className="card-title">
										<h2>{index + 1}</h2>
										<h4>{item.title}</h4>
									</div>
									<p>{item.content}</p>
								</div>
							</div>
						);
					})}
				</div>
				<Button value={"Download App"} type={"contained"} />
			</div>
    </div>
  );
}

export default HowRevaivWorks;
