import React, { useState } from "react";
import './downloadpopup.scss'
import android from '../../Assets/svg/andriodapp.svg';
import apple from '../../Assets/svg/apple black.svg';
import Button from "../Modules/Button";
import { Link } from 'react-router-dom';

function DownloadPopup() {

	const handleRemove = () => {
		const element = document.getElementsById('download_popup')
		element.classList.remove('open')
	}

  return (

	<div className="download_popup">
		<div className="download_overlay"></div>
		<div className="download_box">
			<h4>Please check your inbox to view the results. For a detailed assessment, <strong>DOWNLOAD RevAiv App</strong> and know your PCOS severity and health insights.</h4>
			<div className="download_links">
				<div className="download_link">
					<img src={android} />
				</div>
				<div className="download_link">
					<img src={apple} />
				</div>
			</div>
			<Button value={"Go to Home page"} type={"contained"} onClick={handleRemove}/>
		</div>
	</div>

  );
}

export default DownloadPopup;
