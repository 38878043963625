import React, { useEffect} from "react";
import "./deleteAccount.scss";
import deleteImage1 from '../../Assets/images/deleteAccountimage1.jpg';
import deleteImage2 from '../../Assets/images/deleteAccountimage2.jpg';
import deleteImage3 from '../../Assets/images/deleteAccountimage3.jpg';
function DeleteAccount() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="contact sec_py">
      <div className="container center">
        <h2 className="title">Delete Account</h2>
        <h4 className="title">Steps to delete account from inside the app</h4>
        <ul className="contact_support">
          <li><span>1</span>Login with the exisitng account</li>
          <li><span>2</span>Open the side menu</li>
          <li><span>3</span>Click on the View Profile link to open the Profile screen</li>
          <li className="img"><img src={deleteImage1} /></li>
          <li><span>4</span>Tap the Delete Account button on the Profile screen</li>
          <li className="img"><img src={deleteImage2} /></li>
          <li><span>5</span>Confirm by selecting the "Yes, delete my account" checkbox</li>
          <li className="img"><img src={deleteImage3} /></li>
          <li><span>6</span>Tap the confirm button to immediately delete your account</li>
        </ul>        
      </div>
    </div>
  );
}

export default DeleteAccount;
