import React, { useState } from "react";
import "./about.scss";
import phone from "../../Assets/svg/phonetrack.svg";
import db from "../../Assets/svg/dashboard.svg";
import care from "../../Assets/svg/customercare.svg";
import monitor from "../../Assets/svg/monitor.svg";
function AboutPage() {
  const [list, setList] = useState([
    {
      icon: care,
      content: "A better relationship with their healthcare provider",
    },
    {
      icon: monitor,
      content: "Regularly monitoring their symptoms and health progress",
    },
    {
      icon: phone,
      content: "Making positive lifestyle adjustments",
    },
    {
      icon: db,
      content: "A supportive community to share their journey",
    },
  ]);
  return (
    <div className="about-page sec_py">
			<div className="container">
				<div className="main_title">
					<p>About Us</p>
					<h2>Our Philosophy</h2>
				</div>
				<div className="about-title-text">
					<p>RevAiv is built on the vision to create a positive impact on people's health. <br />Our team and friends who were victims of PCOS & other hormonal complexities came together with clinical experts to find out a solution that is easy to follow and shows visible results through:</p>
				</div>
				<div className="row cards">
					{list.map((item, index) => {
						return (
					<div key={index}className="col_3">
						<div className="card">
							<div className="card_img">
								<img src={item.icon} alt="icon" />
							</div>
							<p>{item.content}</p>
						</div>
					</div>
					);
					})} 
				</div>
			</div>
		</div>
  );
}

export default AboutPage;
