import React, { useState } from "react";
import "./patients.scss";
import Chat from '../../Assets/images/org_Chat with Health Buddy.jpg';
import Dashboard from '../../Assets/images/org_Detailed Health Dashboard.jpg';
import evaluation from '../../Assets/images/org_Detailed Pre-evaluation.jpg';
import Log from '../../Assets/images/org_Log in your cycle.jpg';
import Program from '../../Assets/images/org_Personalized Program.jpg';
import Symptom from '../../Assets/images/org_Personalized Symptom Tracking.jpg';
import Track from '../../Assets/images/org_Track your daily progress.jpg';
import View from '../../Assets/images/org_View your daily progress insight.jpg';

function PatientsPage() {
	const [list, setList] = useState([
		{
			image: Chat,
			titile: "Chat with Health Buddy",
			description: "Whether you have questions, need motivation, or want to discuss your progress, we're here for you.What you’ll get- Enjoy constant access to a support system that understands your journey and can offer assistance whenever you need it.",
		}, {
			image: Dashboard,
			titile: "Detailed Health Dashboard",
			description: "To give you a full overview of all parameters contributing towards your PCOS severity for you to effectively manage them.",
		}, {
			image: Symptom,
			titile: "Personalized Symptom Tracking",
			description: "Track your PCOS symptoms and progress over time. Record data related to weight, menstrual cycles, mood, and more",
		}, {
			image: evaluation,
			titile: "Detailed Pre-evaluation",
			description: "RevAiv offers a comprehensive pre-evaluation dashboard that allows you to evaluate your PCOS symptoms and severity type, your ideal BMI/BMR, and your ideal activity time in a day.",
		}, {
			image: Track,
			titile: "Track your daily progress",
			description: "Identify your current lifestyle and health status.",
		}, {
			image: Log,
			titile: "Log in your cycle",
			description: "Log in your previous cycles to identify if you have Oligomenorrhea, Amenorrhea, Irregular periods.",
		}, {
			image: View,
			titile: "View your daily progress insight",
			description: "Your progress insight helps you identify your daily score trends in comparison to RevAiv's recommended score for you. Achieving the recommended score gives visible results in upcoming months.",
		}, {
			image: Program,
			titile: "Personalized Program",
			description: "RevAiv offers you a personalized program from certified specialists to enhance your lifestyle.",
		}, 
	]);

return (
	<>
	<div className="patients_sec sec_py">
		<div className="container">
			<div className="main_title">
				<h2>RevAiv for Patients</h2>
			</div>
			<div className="row">
				{list.map((item) => {
					return (
						<div className="col_6">
							<div className="patients_box">
								<img src={item.image} />
								<h2>{item.titile}</h2>
								<p>{item.description}</p>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	</div>
	</>
  );
}

export default PatientsPage;
