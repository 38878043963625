import React, { useState } from "react";
import symptomsArrow from "../../Assets/svg/symptoms-arrow.svg";
import symptomsClose from "../../Assets/svg/symptoms-close.svg";

export default function Button() {

	const [btnState, setBtnState] = useState(false);

	function handleClick() {
		setBtnState(btnState => !btnState);
	}

	let toggleClassCheck = btnState ? ' open': '';

  return (
    <div className={`symptoms_box_btn${toggleClassCheck}`} onClick={handleClick}>
		<div className="symptoms_open">View more <img src={symptomsArrow} /></div>
		<div className="symptoms_close"><img src={symptomsClose} /></div>
	</div>
  );
}
