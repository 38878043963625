import React, { useEffect } from 'react'
import './privacy.scss'
function Privacy() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="privacy tnc_privacy sec_py">
      <div className="container justify">
        <h2>Privacy Policy</h2>
        <p>
          RevAiv Health LLC FZ  (“RevAiv Health”, “we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by RevAiv Health LLC FZ.
        </p>
        <p>
          This Privacy Policy applies to our app, and its associated subdomains (collectively, our “Service”) alongside our application, RevAiv. By accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.
        </p>
        <h3>
          What is this privacy for?
        </h3>
        <p>
          This privacy policy pertains to the website <a href="https://revaiv.app/">https://revaiv.app/</a> and all affiliated domains and subdomains, provided by RevAiv Health, and governs the privacy of its users who opt to utilize its services.
        </p>
        <p>
          The policy delineates various aspects concerning user privacy and elucidates the responsibilities and obligations of users, the website, and its owners. Additionally, it comprehensively explains how this website manages, stores, and safeguards user data and information.
        </p>
        <p>
          RevAiv Health reserves the right to modify this policy periodically by updating this page. Users are advised to review this page regularly to ensure their satisfaction with any alterations. This policy is effective as of 1st March 2024.
        </p>
        <h3>
          Definitions and key terms
        </h3>
        <p>
          To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, are strictly defined as:
        </p>
        <p>
          Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.
        </p>
        <p>
          Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to RevAiv Health LLC FZ , (Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, United Arab Emirates ), that is responsible for your information under this Privacy Policy.
        </p>
        <p>
          Country: where RevAiv Health or the owners/founders of RevAiv Health are based, in this case is United Arab Emirates
        </p>
        <p>
          Customer: refers to the company, organization or person that signs up to use the RevAiv Health Service to manage the relationships with your consumers or service users.
        </p>
        <p>
          Device: any internet connected device such as a phone, tablet, computer or any other device that can be used to visit RevAiv Health and use the services.
        </p>
        <p>
          IP address: Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.
        </p>
        <p>
          Personnel: refers to those individuals who are employed by RevAiv Health or are under contract to perform a service on behalf of one of the parties.
        </p>
        <p>
          Personal Data: any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.
        </p>
        <p>
          Service: refers to the service provided by RevAiv Health as described in the relative terms (if available) and on this platform.
        </p>
        <p>
          Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.
        </p>
        <p>
          App/Application: RevAiv app, refers to the SOFTWARE PRODUCT identified above.
        </p>
        <p>
          You: a person or entity that is registered with RevAiv Health to use the Services.
        </p>
        <h3>
          What Information Do We Collect?
        </h3>
        <p>
          We collect information from you when you use our app, visit our site, place an order, subscribe to our newsletter, respond to a survey or fill out a form.
        </p>
        <p>
          We may collect and process the following data about you:
        </p>
        <p>
          <ul>
            <li>Information regarding your visits to our website and the resources you access, including, but not limited to, traffic data, location data, weblog statistics, and other communication data, may be collected.</li>
            <li>We collect Expo Device ID to send push notifications to our app users.</li>
            <li>Information provided by you when filling in forms on our website, such as during sign up or when adding details to your account.</li>
          </ul>
        </p>
        <p>
          This may include, but is not limited to:
        </p>
        <p>
          <ul>
            <li>
              All health data provided by you, including health goals, symptoms, period logs, fitness and diet activities, medications, and supplements, and any other health metrics like hight and weight.
            </li>
            <li>
              Personal identification information, such as name, email, and phone number
            </li>
            <li>
              Information on your browser, IP address, location, and device
            </li>
            <li>
              Demographic information like date of birth, ethnicity, and marital status.
            </li>
            <li>
              Communications data, including messages, chats, and comments.
            </li>
            <li>
              Any images or description of yourself which you voluntarily provide.
            </li>
          </ul>
        </p>
        <h3>
          How Do We Use The Information We Collect?
        </h3>
        <p>
          Any of the information we collect from you is primarily used to enable us to provide our services to you. We may use the information in one of the following ways:
        </p>
        <p>
          <ul>
            <li>To personalize your experience (your information helps us to better respond to your individual needs)</li>
            <li>
              To improve our app (we continually strive to improve our app offerings based on the information and feedback we receive from you)
            </li>
            <li>
              To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)
            </li>
            <li>
              To administer a contest, promotion, survey or other site or app feature such as improvement or service/product changes.
            </li>
            <li>
              To send periodic emails for promotions of our products, services or other information which we think you may find interesting.
            </li>
          </ul>
        </p>
        <h3>
          How you control your information?
        </h3>
        <p>
          You have the option to restrict the collection or utilization of your personal information in the following manners:
        </p>
        <p>
          <ul>
            <li>
              When prompted to fill in a form on the website, seek out the checkbox indicating your preference to refrain from having your information used by us for promotional purposes;
            </li>
            <li>
              If you have previously consented to us using your personal information for direct marketing, you retain the right to change your decision at any time by contacting us via writing or emailing at <a href='mailto:support@revaiv.app'>support@revaiv.app</a>
            </li>
          </ul>
        </p>
        <p>
          We do not sell, distribute, or lease your personal information to third parties unless we have obtained your consent or are compelled by law to do so.
        </p>
        <h3>
          Is the information collected through the RevAiv Service secure?
        </h3>
        <p>
          We take precautions to protect the security of your information. We have physical, electronic, and managerial procedures to help safeguard, prevent unauthorized access, maintain data security, and correctly use your information. However, neither people nor security systems are foolproof, including encryption systems. In addition, people can commit intentional crimes, make mistakes or fail to follow policies. Therefore, while we use reasonable efforts to protect your personal information, we cannot guarantee its absolute security. If applicable law imposes any non-disclaimable duty to protect your personal information, you agree that intentional misconduct will be the standards used to measure our compliance with that duty.
        </p>
        <h3>
          How we use Cookies
        </h3>
        <p>
          RevAiv uses "Cookies" to identify the areas of our app that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to enhance the performance and functionality of our app but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the app as we would not be able to remember that you had logged in previously. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our app correctly or at all. We never place Personally Identifiable Information in Cookies.
        </p>
        <h3>
          Blocking and disabling cookies and similar technologies
        </h3>
        <p>
          Wherever you're located you may also set your browser to block cookies and similar technologies, but this action may block our essential cookies and prevent our app from functioning properly, and you may not be able to fully utilize all of its features and services. You should also be aware that you may also lose some saved information (e.g. saved login details, site preferences) if you block cookies on your browser. Different browsers make different controls available to you. Disabling a cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your browser, you should visit your browser's help menu for more information.
        </p>
        <h3>
          Links to Other Websites
        </h3>
        <p>
          This Privacy Policy applies only to the Services. The Services may contain links to other websites not operated or controlled by RevAiv Health. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.
        </p>
        <h3>
          Can I access my information?
        </h3>
        <p>
          Under the Data Protection Act 1998, you have the right to access the information we hold about you. Please be aware that any request for access may be subject to a small fee, which covers our expenses in providing you with the requested information. If you wish to obtain details regarding the information we hold about you, please contact us using the provided contact information.
        </p>
        <h3>
          Can I update or correct my information?
        </h3>
        <p>
          The rights you have to request updates or corrections to the information RevAiv collects depend on your relationship with RevAiv. Personnel may update or correct their information as detailed in our internal company employment policies.
        </p>
        <p>
          Customers have the right to request the restriction of certain uses and disclosures of personally identifiable information as follows. You can contact us in order to (1) update or correct your personally identifiable information, (2) change your preferences with respect to communications and other information you receive from us, or (3) delete the personally identifiable information maintained about you on our systems (subject to the following paragraph), by cancelling your account. Such updates, corrections, changes and deletions will have no effect on other information that we maintain, or information that we have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or deletion. To protect your privacy and security, we may take reasonable steps (such as requesting a unique password) to verify your identity before granting you profile access or making corrections. You are responsible for maintaining the secrecy of your unique password and account information at all times.
        </p>
        <p>
          You should be aware that it is not technologically possible to remove each and every record of the information you have provided to us from our system. The need to back up our systems to protect information from inadvertent loss means that a copy of your information may exist in a non-erasable form that will be difficult or impossible for us to locate. Promptly after receiving your request, all personal information stored in databases we actively use, and other readily searchable media will be updated, corrected, changed or deleted, as appropriate, as soon as and to the extent reasonably and technically practicable.
        </p>
        <p>
          If you are an end user and wish to update, delete, or receive any information we have about you, you may do so by contacting the organization of which you are a customer.
        </p>
        <h3>
          Do we share the information we collect with third parties?
        </h3>
        <p>
          The contract governed by this policy is mutually beneficial for both parties and is not intended to confer benefits or enforceability upon any third party.
        </p>
        <p>
          The exercise of rights by the parties under a contract based on this policy does not require the consent of any third party.
        </p>
        <h3>
          How Long Do We Keep Your Information?
        </h3>
        <p>
          We keep your information only so long as we need it to provide RevAiv to you and fulfill the purposes described in this policy. When we no longer need to use your information and there is no need for us to keep it to comply with our legal or regulatory obligations, we’ll either remove it from our systems or depersonalize it so that we can't identify you.
        </p>
        <h3>
          Governing Law
        </h3>
        <p>
          This Privacy Policy is governed by the laws of United Arab Emirates without regard to its conflict of laws provision. You consent to the exclusive jurisdiction of the courts in connection with any action or dispute arising between the parties under or in connection with this Privacy Policy except for those individuals who may have rights to make claims under Privacy Shield, or the Swiss-US framework.
        </p>
        <p>
          The laws of United Arab Emirates , excluding its conflicts of law rules, shall govern this Agreement and your use of the app. Your use of the app may also be subject to other local, state, national, or international laws.
        </p>
        <p>
          By using RevAiv or contacting us directly, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, you should not engage with our app, or use our services. Continued use of the app, direct engagement with us, or following the posting of changes to this Privacy Policy that do not significantly affect the use or disclosure of your personal information will mean that you accept those changes.
        </p>
        <h3>
          Your Consent
        </h3>
        <p>
          We've updated our Privacy Policy to provide you with complete transparency into what is being set when you visit our site and how it's being used. By using our RevAiv , registering an account, or making a purchase, you hereby consent to our Privacy Policy and agree to its terms.
        </p>
        <h3>
          Changes To Our Privacy Policy
        </h3>
        <p>
          We may change our Service and policies, and we may need to make changes to this Privacy Policy so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to this Privacy Policy and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Privacy Policy. If you do not want to agree to this or any updated Privacy Policy, you can delete your account.
        </p>
        <h3>
          Contact Us
        </h3>
        <p>
          Don't hesitate to contact us if you have any questions.
        </p>
        <p>
          Via Email: <a href="mailto:support@revaiv.app">support@revaiv.app</a>
        </p>
        <p>
          Via this Link: <a href="https://revaiv.app/#/contact-us">https://revaiv.app/#/contact-us</a>
        </p>
        <p>
          Via this Address: Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, United Arab
          Emirates
        </p>
      </div>
    </div>
  );
}

export default Privacy







