import React, { useState, useRef } from "react";
import "./landing.scss";
import landingImage from "../../Assets/images/screensdisplay1.png"
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useEffect } from "react";
import { json, useLocation } from "react-router-dom";

function LandingPage() {
    const [inputFields, setInputFields] = useState({});
    const [errors, setErrors] = useState({});
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [loader, setLoader] = useState(false);
    const [countries, setCountries] = useState([]);
    const [open, setOpen] = useState(false);
    const formRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

    useEffect(() => {

        if(location.state != null) {
            setInputFields({ ...inputFields, ['email']: location.state.email });
        }
        const fetchData = async () => {
            try {
                const response = await fetch('https://afd-revaiv-api-prod-efa5aqc5acbsc4f7.z01.azurefd.net/api/Public/countries');
                if (response.status == 200) {
                    const jsonData = await response.json();
                    setCountries(jsonData);
                }
            } catch (error) {
                console.error('Error fetching countries', error);
            }
        };
    
        fetchData();
    }, []);

    const userRegistration = async (data) => {
        try {
            const endPoint = 'https://afd-revaiv-api-prod-efa5aqc5acbsc4f7.z01.azurefd.net/api/Public/betaUserRegistration';
            const response = await fetch(endPoint, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (response.status == 200) {
                const jsonData = await response.json();
                setMessage(jsonData.message);
                setLoader(false);
                setStatus('success');
                setOpen(true)
                if(location.state != null) {
                    location.state = null;
                }
                formRef.current.reset();
            }

            if(response.status == 400) {
                const jsonData = await response.json();
                setMessage(jsonData.title);
                setLoader(false);
                setStatus('error');
            }
        } catch (error) {
            setLoader(false);
            setMessage(error.toString());
            setStatus('error');
            console.error('unable to save:', error);
        }
    }

    const handleChange = (e) => {
        setInputFields({ ...inputFields, [e.target.name]: e.target.value });

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoader(true);
        setErrors(validateValues(inputFields));
        if(Object.keys(validateValues(inputFields)).length === 0) {
            var data = {
                'emailId':inputFields.email,
                'formData':{
                    'name':inputFields.name,
                    'countryAlphaCode':inputFields.country,
                    'isPcosDiagnosed': inputFields.isPcosDiagnosed,
                    'platform': inputFields.platform
                }
                
            }
            userRegistration(data)
        }
    };

    const validateValues = (inputValues) => {
        let errors = {};
        if (inputValues.name == "") {
            errors.name = "please enter your name";
        }
        if (inputValues.email == "") {
          errors.email = "please enter your email";
        }
        if (inputValues.country == "") {
            errors.country = "please select your country";
        }

        if (inputValues.isPcosDiagnosed == "") {
          errors.isPcosDiagnosed = "please select one option";
        }

        if (inputValues.platform == "") {
            errors.platform = "please select your device type";
        }

        return errors;
    };

    const handleDialog = () => {
        setOpen(false);
    }
    
return (
	<>
	<div className="landing_page_sec sec_py">
		<div className="container">
			<div className="main_title">
				<h2>Welcome to the RevAiv Beta program Registration!</h2>
			</div>
            <div className="main_content">
                <p className="bold">Thank you for expressing your interest in joining our Beta Test Program and assisting us in building the ultimate companion app for the PCOS community.</p>
                <p>
                    Here at <strong>RevAiv</strong>, we recognize the challenges faced by users suffering from PCOS who often encounter false promises claiming to cure the condition. We understand that PCOS can only be managed effectively with the right approach and proper support, which can help alleviate symptoms over time.
                </p>
                <p>
                    Join us today and become a part of this journey to make a positive impact on your life and the lives of fellow PCOS revivors. our feedback is invaluable to us as we strive for continuous improvement.
                </p>
                <p className="bold">Your Feedback Matters immensely!</p>
                <div className="img_section">
                    <img src={landingImage} />
                </div>
            </div>
            <div className="form_section">
                {(status == 'error') ? (
                    <p className="error">{message}</p>
                ) : null}
                <form ref={formRef} onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label className="label">Enter your full name</label>
                        <input 
                            className="form-control" 
                            name="name" 
                            placeholder="Please enter your full name" 
                            onChange={handleChange}
                            required
                        />
                        {errors.name ? (
                            <p className="error">{errors.name}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <label className="label">From which country are you participating?</label>
                        <select className="form-control" name="country" onChange={handleChange} required>
                            <option value="">Please select your country</option>
                            {
                                countries.map((country, index) => 
                                    <option value={country.alphaCode} key={index}>{country.name}</option>
                                )
                            }
                        </select>
                        {errors.country ? (
                            <p className="error">{errors.country}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <label className="label">Are you diagnosed with PCOS?</label>
                        <div className="radio-control">
                            <div className="radio">
                                <input 
                                    id="radio-1" 
                                    name="isPcosDiagnosed" 
                                    type="radio" 
                                    onChange={handleChange}
                                    value={'NotSurePCOS'}
                                    required
                                />
                                <label htmlFor="radio-1" className="radio-label">Not sure</label>
                            </div>
                            <div className="radio">
                                <input 
                                    id="radio-2" 
                                    name="isPcosDiagnosed" 
                                    type="radio" 
                                    onChange={handleChange}
                                    value={'DiagnosedWithPCOS'}
                                    required
                                />
                                <label htmlFor="radio-2" className="radio-label">Yes</label>
                            </div>
                            <div className="radio">
                                <input 
                                    id="radio-3" 
                                    name="isPcosDiagnosed" 
                                    type="radio"
                                    onChange={handleChange}
                                    value={'NotDiagnosedWithPCOS'}
                                    required
                                />
                                <label htmlFor="radio-3" className="radio-label">No</label>
                            </div>
                            {errors.isPcosDiagnosed ? (
                                <p className="error">{errors.isPcosDiagnosed}</p>
                            ) : null}
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="label">Enter your email address</label>
                        <input 
                            type="email" 
                            className="form-control" 
                            name="email" 
                            placeholder="Please enter your email address" 
                            onChange={handleChange}
                            defaultValue={(location.state != null) ? location.state.email : ''}
                            required
                        />
                        {errors.email ? (
                            <p className="error">{errors.email}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <label className="label">Which mobile phone are you using to test our app?</label>
                        <div className="radio-control">
                            <div className="radio">
                                <input 
                                    id="radio-4" 
                                    name="platform" 
                                    type="radio" 
                                    value={'android'}
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="radio-4" className="radio-label">Android</label>
                            </div>
                            <div className="radio">
                                <input 
                                    id="radio-5" 
                                    name="platform" 
                                    type="radio" 
                                    value={'ios'}
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="radio-5" className="radio-label">iOS</label>
                            </div>
                        </div>
                        {errors.platform ? (
                            <p className="error">{errors.platform}</p>
                        ) : null}
                    </div>
                    <div className="btn_section">
                        <button type="submit">{ (loader == true) ? <div class="loader"></div> : 'Register Now'}</button>
                    </div>
                </form>
            </div>
            <div className="copyright">© {new Date().getFullYear()} RevAiv Health LLC-FZ | All Rights Reserved.</div>
		</div>
	</div>
    <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle align="center" style={{fontWeight:"700"}}>{message}</DialogTitle>
        <div className="btn-div" style={{textAlign:"center", marginBottom:"10px"}}>
            <button className="contained" onClick={handleDialog}>ok</button>
        </div>
    </Dialog>
	</>
  );
}

export default LandingPage;
